<template>
  <forms />
</template>

<script>
import Forms from "@/components/forms/Forms.vue";

export default {
  name: "forms-view",
  components: {
    forms: Forms
  },
  data() {
    return {};
  }
};
</script>

<style scoped></style>
