<template>
  <v-dialog v-model="showModal" persistent max-width="fit-content">
    <v-card>
      <v-card-title class="headline break-word">
        {{ title }}
      </v-card-title>
      <v-card-text class="break-word">
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="text-justify" color="secondary" dark @click="okAction">
          {{ ok }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "success-message",
  props: {
    showModal: { type: Boolean, required: true },
    title: { type: String, required: true },
    message: { type: String, required: true },
    ok: { type: String, required: false, default: "Ok" }
  },
  methods: {
    okAction() {
      this.$emit("okAction");
    }
  }
};
</script>
<style scoped>
.break-word {
  word-break: break-word;
}
</style>
