<template>
  <v-container class="pa-6 content-relative">
    <v-container>
      <v-row class="pb-0">
        <v-col
          cols="12"
          md="11"
          :class="{ 'text-center': $vuetify.breakpoint.smAndDown }"
          class="pb-0"
        >
          <br v-if="$vuetify.breakpoint.smAndDown" />
          <div class="title-primary text-sm-h5">
            {{ section.name }}
          </div>
          <h5 class="title-primary text-sm-body-2">
            {{ section.description }}
          </h5>
        </v-col>
        <v-col
          cols="12"
          md="1"
          :align="$vuetify.breakpoint.mdAndDown ? 'end' : 'start'"
          class="pb-0"
        >
          <v-menu attach offset-y class="ml-5">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="1"
                fab
                small
                v-bind="attrs"
                v-on="on"
                icon
                class="button-primary content-absolute mt-4 mr-14"
              >
                <v-icon color="primary">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="modifySection(section)">
                <v-list-item-title>Editar</v-list-item-title> </v-list-item
              ><v-list-item
                v-if="sectionCanBeDeleted"
                @click="setSectionDeletion(section.id)"
              >
                <v-list-item-title>Eliminar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
    <hr />
    <br />

    <v-container>
      <v-row>
        <v-col
          cols="12"
          v-for="(schema, index) in schemas"
          :key="`${index}-${schema.label}`"
        >
          <v-card
            elevation="4"
            class="py-5 pl-5 question-card-border content-relative"
          >
            <v-menu attach offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="1"
                  fab
                  small
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="button-primary content-absolute mt-4 mr-4"
                >
                  <v-icon color="primary">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="modifyQuestion(schema.fields[0].id)">
                  <v-list-item-title>Editar</v-list-item-title> </v-list-item
                ><v-list-item
                  v-if="questionCanBeDeleted"
                  @click="setQuestionDeletion(schema.fields[0].id)"
                >
                  <v-list-item-title>Eliminar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <br />
            <v-row>
              <v-col cols="11">
                <form-input :schema="schema" />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-btn
            block
            color="primary"
            class="white--text"
            @click="createQuestion"
            large
          >
            <v-icon>mdi-plus</v-icon>
            Añadir pregunta
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <br />
    <br />
    <question-form-modal
      v-if="showQuestionModal"
      :showModal="showQuestionModal"
      :titleToShow="questionModalTitle"
      :questionToModify="questionToModify"
      :questionTypes="questionTypes"
      :section="section"
      @closeModal="openQuestionModal"
      @loadForm="loadForm"
    />
    <section-form-modal
      v-if="showSectionModal"
      :showModal="showSectionModal"
      :titleToShow="sectionModalTitle"
      :sectionToModify="sectionToModify"
      :form="form"
      @closeModal="openSectionModal"
      @loadForm="loadForm"
    />

    <warning-message
      :loading="loadingDeletion"
      :showModal="showWarningMessage"
      ok="Eliminar"
      title="¿Desea eliminar esta pregunta?"
      message="La pregunta desaparecerá de su formulario. Esta acción no se podrá revertir"
      @cancelAction="openWarningMessage"
      @okAction="deleteQuestion"
    />

    <warning-message
      :loading="loadingDeletion"
      :showModal="showSectionWarningMessage"
      ok="Eliminar"
      title="¿Desea eliminar esta sección?"
      message="La sección desaparecerá de su formulario. Esta acción no se podrá revertir"
      @cancelAction="openSectionWarningMessage"
      @okAction="deleteSection"
    />
    <success-message
      :showModal="showSuccessMessage"
      :title="successTitle"
      :message="succesMessage"
      @okAction="successfulTransaction()"
    />
  </v-container>
</template>

<script>
import WarningMessage from "@/components/common/notifications/WarningMessage";
import SuccessMessage from "@/components/common/notifications/SuccessMessage";
import FormInput from "./FormInput";
import QuestionFormModal from "./QuestionFormModal";
import SectionFormModal from "./SectionFormModal";

import Repository from "@/services/repositories/repositoryFactory";
const FormRepository = Repository.get("form");

import { QUESTION_TYPES } from "@/config/constants";

export default {
  name: "form-section-template",
  components: {
    "form-input": FormInput,
    "warning-message": WarningMessage,
    "success-message": SuccessMessage,
    "question-form-modal": QuestionFormModal,
    "section-form-modal": SectionFormModal
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    questionTypes: {
      type: Array,
      required: true
    },
    numberOfSectionsWithQuestions: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showQuestionModal: false,
      showSectionModal: false,
      questionModalTitle: "",
      sectionModalTitle: "",
      showSectionWarningMessage: false,
      showWarningMessage: false,
      showSuccessMessage: false,
      loadingDeletion: false,

      schemas: [],
      questionIdToBeDeleted: null,
      questionToModify: null,
      sectionIdToBeDeleted: null,
      sectionToModify: null,
      successTitle: "",
      succesMessage: "",
      questionCanBeDeleted: true,
      sectionCanBeDeleted: true
    };
  },
  methods: {
    openQuestionModal(visibility) {
      this.showQuestionModal = visibility;
    },
    openSectionModal(visibility) {
      this.showSectionModal = visibility;
    },
    openWarningMessage(visibility) {
      this.showWarningMessage = visibility;
    },
    openSectionWarningMessage(visibility) {
      this.showSectionWarningMessage = visibility;
    },
    openSuccessMessage(visibility) {
      this.showSuccessMessage = visibility;
    },
    loadForm() {
      this.$emit("loadForm");
    },
    successfulTransaction() {
      this.openSuccessMessage(false);
      this.loadForm();
    },
    setQuestionDeletion(questionId) {
      this.questionIdToBeDeleted = questionId;
      this.openWarningMessage(true);
    },
    async deleteQuestion() {
      this.successTitle = "";
      this.succesMessage = "";
      this.loadingDeletion = true;
      await FormRepository.deleteQuestionById(this.questionIdToBeDeleted)
        .then(() => {
          this.successTitle = "¡Se ha eliminado la pregunta exitosamente!";
          this.succesMessage =
            "La pregunta se ha eliminado y ya no será visualizado en su formulario";
          this.openSuccessMessage(true);
        })
        .finally(() => {
          this.loadingDeletion = false;
          this.openWarningMessage(false);
          this.questionIdToBeDeleted = null;
        });
    },
    setSectionDeletion(sectionId) {
      this.sectionIdToBeDeleted = sectionId;
      this.openSectionWarningMessage(true);
    },
    async deleteSection() {
      this.successTitle = "";
      this.succesMessage = "";
      this.loadingDeletion = true;
      await FormRepository.deleteSectionById(this.sectionIdToBeDeleted)
        .then(() => {
          this.successTitle = "¡Se ha eliminado la sección exitosamente!";
          this.succesMessage =
            "La sección se ha eliminado y ya no será visualizado en su formulario";
          this.openSuccessMessage(true);
        })
        .finally(() => {
          this.loadingDeletion = false;
          this.openSectionWarningMessage(false);
          this.questionIdToBeDeleted = null;
        });
    },
    createQuestion() {
      this.questionToModify = null;
      this.questionModalTitle = `Crear nueva pregunta - ${this.section.name}`;
      this.openQuestionModal(true);
    },
    modifyQuestion(questionId) {
      this.questionToModify = this.section.questions.find(
        question => question.id === questionId
      );
      this.questionModalTitle = `Modificar pregunta - ${this.section.name}`;
      this.openQuestionModal(true);
    },
    modifySection(section) {
      this.sectionToModify = section;
      this.sectionModalTitle = `Modificar sección`;
      this.openSectionModal(true);
    },
    returnInputModelName(questionType) {
      switch (questionType) {
        case QUESTION_TYPES.SINGLE_CHOICE.id:
          return QUESTION_TYPES.SINGLE_CHOICE.name;
        case QUESTION_TYPES.MULTIPLE_CHOICE.id:
          return QUESTION_TYPES.MULTIPLE_CHOICE.name;
        case QUESTION_TYPES.TEXT.id:
          return QUESTION_TYPES.TEXT.name;
        case QUESTION_TYPES.TEXTAREA.id:
          return QUESTION_TYPES.TEXTAREA.name;
        case QUESTION_TYPES.DATE.id:
          return QUESTION_TYPES.DATE.name;
        case QUESTION_TYPES.SWITCH.id:
          return QUESTION_TYPES.SWITCH.name;
      }
    },
    formatQuestion(question) {
      let schema = {
        fields: [
          {
            type: this.returnInputModelName(question.questionType.id),
            id: question.id,
            label: `${question.position}.- ${question.name}`,
            model: "value",
            placeholder: question.label,
            hint: question.hint,
            required: question.required,
            position: question.position,
            disabled: false,
            readonly: true,
            adminView: true
          }
        ]
      };
      if (
        question.questionType.id === QUESTION_TYPES.SINGLE_CHOICE.id ||
        question.questionType.id === QUESTION_TYPES.MULTIPLE_CHOICE.id
      ) {
        schema.fields[0] = { ...schema.fields[0], options: question.options };
      } else {
        if (question.questionType.id === QUESTION_TYPES.SWITCH.id) {
          schema.fields[0] = {
            ...schema.fields[0],
            multi: true,
            featured: false,
            default: false,
            textOn: "Verdadero",
            textOff: "Falso"
          };
        }
      }
      return schema;
    },
    createSchemas(questions) {
      questions.forEach(question => {
        let questionSchema = this.formatQuestion(question);
        this.schemas.push(questionSchema);
      });
    }
  },
  mounted() {
    if (
      this.section?.questions?.length &&
      this.section?.questions?.length <= 1
    ) {
      this.questionCanBeDeleted = false;
    }
    if (
      this.section?.questions?.length &&
      this.numberOfSectionsWithQuestions == 1
    ) {
      this.sectionCanBeDeleted = false;
    }
    this.createSchemas(this.section.questions);
  }
};
</script>

<style scoped>
.title-primary {
  color: var(--v-primary-base);
}

.button-primary {
  border: solid 2px var(--v-primary-base);
  color: var(--v-primary-base);
}
.content-relative {
  position: relative;
}
.content-absolute {
  position: absolute;
  top: 0;
  right: 0;
}
.question-card-border {
  border: solid 3px var(--v-primary-base);
}
</style>
