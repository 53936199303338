<template>
  <v-dialog v-model="showModal" persistent width="450px">
    <v-card color="white">
      <v-toolbar
        light
        class="headline d-flex justify-center"
        style="background-color: var(--v-secondary-base)"
      >
        <v-icon x-large color="white">mdi-alert</v-icon>
      </v-toolbar>
      <v-card>
        <v-card-title
          class="text-sm-h5 text-h6 break-word text-center justify-cente-title"
        >
          {{ title }}
        </v-card-title>
        <v-card-text class="break-word text-center">
          {{ message }}
        </v-card-text>
        <v-card-actions
          class="d-flex"
          :class="$vuetify.breakpoint.xsOnly ? 'justify-center' : 'justify-end'"
        >
          <v-btn color="secondary" dark @click="okAction" :loading="loading">{{
            ok
          }}</v-btn>
          <v-btn
            v-if="!onlyReadNotification"
            color="#eb655e"
            dark
            @click="cancelAction"
            :disabled="loading"
            >{{ cancel }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "warning-message",
  props: {
    showModal: { type: Boolean, required: true },
    loading: { type: Boolean, default: false },
    title: { type: String, required: true },
    message: { type: String, required: true },
    ok: { type: String, required: false, default: "Ok" },
    cancel: { type: String, required: false, default: "cancelar" },
    onlyReadNotification: { type: Boolean, required: false, default: false }
  },
  methods: {
    okAction() {
      this.$emit("okAction");
    },
    cancelAction() {
      this.$emit("cancelAction", false);
    }
  }
};
</script>
<style scoped>
.break-word {
  word-break: break-word;
}
.justify-cente-title {
  justify-content: center;
}
</style>
