<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="2">
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-skeleton-loader tile type="image"></v-skeleton-loader>
          <v-skeleton-loader tile type="image"></v-skeleton-loader>
          <v-skeleton-loader tile type="image"></v-skeleton-loader>
          <v-skeleton-loader tile type="image"></v-skeleton-loader>
        </template>
        <template v-else>
          <v-skeleton-loader
            tile
            type="image"
            class="loader-height"
          ></v-skeleton-loader>
        </template>
      </v-col>
      <v-col cols="12" md="10">
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="8">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </v-col>
            <v-col
              class="d-flex align-start"
              :class="
                $vuetify.breakpoint.smAndUp ? 'justify-end' : ' justify-center'
              "
              cols="12"
              sm="4"
            >
              <v-skeleton-loader type="chip"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <hr />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader type="image"></v-skeleton-loader>
              <br />
              <v-skeleton-loader type="image"></v-skeleton-loader>
              <br />
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "form-loader",
  data() {
    return {};
  }
};
</script>

<style scoped>
.loader-height {
  height: 50px;
}
</style>
