<template>
  <v-dialog
    v-model="showModal"
    transition="dialog-bottom-transition"
    persistent
    fullscreen
  >
    <v-card>
      <v-toolbar dark color="primary" class="toolbar">
        <v-btn icon dark @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ titleToShow }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="saveQuestion"
            :loading="loading"
            :disabled="updatingOption"
            >Guardar</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>
      <br />
      <v-card-text>
        <v-container class="mt-7">
          <v-form ref="question">
            <v-row>
              <v-col cols="12" md="6">
                <h1 class="mb-5  text-h6 primary--text">
                  Pregunta
                </h1>
                <v-text-field
                  v-model.trim="question.name"
                  :rules="[rules.required, rules.requiredText]"
                  prepend-inner-icon="mdi-help"
                  label="Pregunta"
                  outlined
                  maxlength="200"
                  counter
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <h1 class="mb-5 text-h6 primary--text">
                  Tipo de pregunta
                </h1>
                <v-autocomplete
                  :items="filteredQuestionTypes"
                  item-text="name"
                  item-value="id"
                  prepend-inner-icon="mdi-format-list-bulleted-type"
                  v-model="question.questionType.id"
                  :rules="[rules.required]"
                  outlined
                  attach
                  label="Tipo de pregunta"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <h1
                  class="mb-5 text-h6"
                  :class="
                    isBooleanQuestion || isSelectionQuestion
                      ? 'gray--text'
                      : 'primary--text'
                  "
                >
                  Texto secundario
                </h1>
                <v-text-field
                  v-model.trim="question.label"
                  prepend-inner-icon="mdi-format-title"
                  label="Texto secundario"
                  :rules="[rules.requiredText]"
                  outlined
                  :disabled="isBooleanQuestion || isSelectionQuestion"
                  maxlength="200"
                  counter
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <h1
                  class="mb-5 text-h6"
                  :class="isBooleanQuestion ? 'gray--text' : 'primary--text'"
                >
                  Texto de apoyo
                </h1>
                <v-text-field
                  v-model.trim="question.hint"
                  prepend-inner-icon="mdi-format-title"
                  label="Texto de apoyo"
                  :rules="[rules.requiredText]"
                  outlined
                  :disabled="isBooleanQuestion"
                  maxlength="200"
                  counter
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <h1 class="mb-5 text-h6 primary--text">
                  Pregunta obligatoria
                </h1>
                <v-checkbox
                  v-model="question.required"
                  label="Obligatoria"
                  value
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6" v-if="questionToModify">
                <h1 class="mb-5 text-h6 primary--text">
                  Posición
                </h1>
                <v-select
                  v-model="question.position"
                  :items="positions"
                  prepend-inner-icon="mdi-order-numeric-ascending"
                  label="Orden de aparición"
                  outlined
                  attach
                ></v-select>
              </v-col>
            </v-row>
            <template v-if="isSelectionQuestion">
              <v-row>
                <v-col cols="12">
                  <h1 class="mb-5 text-h6 primary--text">
                    Opciones
                  </h1>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="11">
                  <v-text-field
                    v-model.trim="newOptionName"
                    prepend-inner-icon="mdi-format-title"
                    label="Nombre de la opción"
                    outlined
                    :disabled="updatingOption"
                    :rules="[rules.requiredText, rules.requiredOptions]"
                    maxlength="150"
                    counter
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                  class="d-flex justify-center align-start pt-6"
                >
                  <v-btn
                    v-if="$vuetify.breakpoint.smAndUp"
                    outlined
                    color="primary"
                    icon
                    :disabled="!newOptionName"
                    @click="addOption"
                    ><v-icon color="primary">mdi-plus</v-icon></v-btn
                  >
                  <v-btn
                    v-else
                    outlined
                    tile
                    color="primary"
                    block
                    :disabled="!newOptionName"
                    icon
                    @click="addOption"
                    ><v-icon color="primary">mdi-plus</v-icon></v-btn
                  >
                </v-col>
              </v-row>
              <v-row v-if="questionOptions.length">
                <v-col cols="12">
                  <v-container class="scroll-style wrapper pr-8">
                    <v-card class="mx-auto card-background" tile>
                      <v-list flat class="card-background py-0 ">
                        <v-list-item-group color="primary">
                          <template v-for="(option, i) in questionOptions">
                            <v-list-item
                              :key="i + '-' + option.name"
                              v-if="
                                !option.status ||
                                  (option.status &&
                                    option.status.id == status.ACTIVE.id)
                              "
                              class=" card-border "
                              :ripple="false"
                            >
                              <v-list-item-content>
                                <v-list-item-title
                                  class="white--text"
                                  v-if="!option.edit"
                                  ><h3>
                                    {{ `${option.position}.- ${option.name}` }}
                                  </h3></v-list-item-title
                                >
                                <v-row v-else>
                                  <v-col cols="2">
                                    <v-select
                                      v-model="optionToUpdatePosition"
                                      :items="optionPositions"
                                      prepend-inner-icon="mdi-order-numeric-ascending"
                                      label="Posición"
                                      outlined
                                      color="white"
                                      class="border input-color "
                                      hide-details
                                      absolute
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="10">
                                    <v-text-field
                                      v-model.trim="optionToUpdateName"
                                      prepend-inner-icon="mdi-format-title"
                                      label="Nombre de la opción"
                                      outlined
                                      color="white"
                                      class="border input-color "
                                      maxlength="150"
                                      counter
                                      hide-details
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-list-item-content>
                              <v-list-item-icon>
                                <v-row v-if="!option.edit">
                                  <v-col
                                    class="d-flex justify-center align-center"
                                  >
                                    <v-tooltip top>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          @click="editOption(option)"
                                          v-bind="attrs"
                                          v-on="on"
                                          :disabled="updatingOption"
                                          icon
                                        >
                                          <v-icon color="white">
                                            mdi-pencil
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          @click="removeOption(option)"
                                          v-bind="attrs"
                                          v-on="on"
                                          :disabled="updatingOption"
                                          icon
                                        >
                                          <v-icon color="white">
                                            mdi-close
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Eliminar</span>
                                    </v-tooltip>
                                  </v-col>
                                </v-row>
                                <v-row v-else>
                                  <v-col
                                    class="d-flex justify-center align-center"
                                  >
                                    <v-tooltip top>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          @click="saveOption(option)"
                                          v-bind="attrs"
                                          v-on="on"
                                          :disabled="!optionToUpdateName"
                                          icon
                                        >
                                          <v-icon color="white">
                                            mdi-check
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Guardar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          @click="cancelOption(option)"
                                          v-bind="attrs"
                                          v-on="on"
                                          icon
                                        >
                                          <v-icon color="white">
                                            mdi-cancel
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Cancelar</span>
                                    </v-tooltip>
                                  </v-col>
                                </v-row>
                              </v-list-item-icon>
                            </v-list-item>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </v-container>
                </v-col>
              </v-row>
            </template>
          </v-form>
        </v-container>
      </v-card-text>
      <success-message
        :showModal="showSuccessMessage"
        :title="successTitle"
        :message="successMessage"
        @okAction="successfulTransaction()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import SuccessMessage from "@/components/common/notifications/SuccessMessage";
import Repository from "@/services/repositories/repositoryFactory";
const FormRepository = Repository.get("form");

import { QUESTION_TYPES } from "@/config/constants";
import { STATUS } from "@/config/constants";
export default {
  name: "question-form-modal",
  components: {
    "success-message": SuccessMessage
  },
  props: {
    showModal: { type: Boolean, required: true },
    titleToShow: { type: String, required: true },
    questionToModify: {
      type: Object,
      default: null
    },
    questionTypes: {
      type: Array,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rules: {
        required: value => !!value || "Campo Obligatorio",
        requiredText: value =>
          /\S/.test(value) || value == "" || "No se admiten campos en blanco",
        requiredOptions: () =>
          !!this.questionOptions.length ||
          !this.savingQuestion ||
          "Debe añadir al menos una opción"
      },
      loading: false,
      showSuccessMessage: false,
      successTitle: "",
      successMessage: "",
      newOptionName: "",
      filteredQuestionTypes: [],
      removedQuestionOptions: [],
      optionToUpdateName: "",
      updatingOption: false,
      optionToUpdatePosition: 0,
      questionOptions: [],
      savingQuestion: false,
      status: STATUS,
      question: {
        section: {
          id: this.section.id
        },
        id: null,
        name: null,
        label: null,
        hint: null,
        required: false,
        position: null,
        questionType: {
          id: null
        },
        status: {
          id: null
        }
      }
    };
  },
  computed: {
    positions() {
      return Array.from(
        { length: this.section.questions.length },
        (x, y) => y + 1
      );
    },
    optionPositions() {
      return Array.from(
        { length: this.questionOptions.length },
        (x, y) => y + 1
      );
    },
    isSelectionQuestion() {
      return (
        this.question.questionType.id == QUESTION_TYPES.SINGLE_CHOICE.id ||
        this.question.questionType.id == QUESTION_TYPES.MULTIPLE_CHOICE.id
      );
    },
    isBooleanQuestion() {
      return this.question.questionType.id == QUESTION_TYPES.SWITCH.id;
    }
  },
  methods: {
    validateForm() {
      return this.$refs.question.validate();
    },
    loadForm() {
      this.$emit("loadForm");
    },
    successfulTransaction() {
      this.loadForm();
      this.showSuccessMessage = false;
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal", false);
    },
    addOption() {
      let newOption = {
        name: this.newOptionName,
        position: this.questionOptions.length + 1,
        status: null,
        edit: false
      };
      this.questionOptions.push(newOption);
      this.newOptionName = "";
    },
    saveOption(optionToSave) {
      if (optionToSave.position !== this.optionToUpdatePosition) {
        let index = this.questionOptions.indexOf(optionToSave);
        this.questionOptions.splice(index, 1);
        this.questionOptions.splice(
          this.optionToUpdatePosition - 1,
          0,
          optionToSave
        );
        let position = 1;
        this.questionOptions.forEach(option => {
          option.position = position;
          position++;
        });
      }
      optionToSave.name = this.optionToUpdateName;
      optionToSave.position = this.optionToUpdatePosition;
      optionToSave.edit = false;
      this.updatingOption = false;
      this.optionToUpdateName = "";
      this.optionToUpdatePosition = "";
    },
    cancelOption(option) {
      option.edit = false;
      this.updatingOption = false;
      this.optionToUpdateName = "";
      this.optionToUpdatePosition = "";
    },
    editOption(optionToEdit) {
      optionToEdit.edit = true;
      this.optionToUpdateName = optionToEdit.name;
      this.optionToUpdatePosition = optionToEdit.position;
      this.updatingOption = true;
    },
    removeOption(optionToRemove) {
      let index = this.questionOptions.indexOf(optionToRemove);
      if (this.questionOptions[index].status) {
        this.questionOptions[index].status.id = STATUS.DELETED.id;
        delete this.questionOptions[index].edit;
        this.removedQuestionOptions.push(this.questionOptions[index]);
      }
      this.questionOptions.splice(index, 1);
      let position = 1;
      this.questionOptions.forEach(option => {
        option.position = position;
        position++;
      });
    },
    async saveQuestion() {
      this.savingQuestion = true;
      if (this.validateForm()) {
        if (!this.questionToModify) {
          await this.createQuestion();
        } else {
          await this.updateQuestion();
        }
      }
      this.savingQuestion = false;
    },
    async createQuestion() {
      this.loading = true;
      if (this.questionOptions.length) {
        this.questionOptions.forEach(option => {
          delete option.edit;
          if (!option.status) {
            option.status = { id: STATUS.ACTIVE.id };
          }
        });
        this.question = { ...this.question, options: this.questionOptions };
      }
      delete this.question.id;
      this.question.status.id = STATUS.ACTIVE.id;
      this.question.position = this.section.questions.length + 1;
      await FormRepository.createQuestion(this.question)
        .then(() => {
          this.createdSuccessfully();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateQuestion() {
      this.loading = true;
      if (this.questionOptions.length) {
        this.questionOptions.forEach(option => {
          delete option.edit;
          if (!option.status) {
            option.status = { id: STATUS.ACTIVE.id };
          }
        });
        this.questionOptions = [
          ...this.questionOptions,
          ...this.removedQuestionOptions
        ];
        this.question = { ...this.question, options: this.questionOptions };
      }
      let questionToUpdate = this.section.questions.find(
        question => question.id === this.question.id
      );
      if (questionToUpdate.position != this.question.position) {
        await this.updateAllQuestions();
      } else {
        await FormRepository.updateQuestion(this.question)
          .then(() => {
            this.updatedSuccessfully();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async updateAllQuestions() {
      let questionsToUpdate = [];
      this.section.questions.forEach(question => {
        let questionAux = Object.assign({}, question);
        if (questionAux.id != this.question.id) {
          delete questionAux.status.name;
          delete questionAux.questionType.name;
          delete questionAux.options;
        }
        questionsToUpdate.push(questionAux);
      });
      let questionBeingUpdated = questionsToUpdate.find(
        question => question.id === this.question.id
      );
      let index = questionsToUpdate.indexOf(questionBeingUpdated);
      questionsToUpdate.splice(index, 1);
      questionsToUpdate.splice(this.question.position - 1, 0, this.question);
      let position = 1;
      questionsToUpdate.forEach(question => {
        question.position = position;
        position++;
      });
      await FormRepository.updateQuestionsOrder(questionsToUpdate)
        .then(() => {
          this.updatedSuccessfully();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createdSuccessfully() {
      this.successTitle = "¡Se ha creado la pregunta exitosamente!";
      this.successMessage =
        "La pregunta se ha registrado y podrá ser visualizada en el formulario";
      this.showSuccessMessage = true;
    },
    updatedSuccessfully() {
      this.successTitle = "¡Se ha modificado la pregunta exitosamente!";
      this.successMessage =
        "La pregunta se ha modificado y podrá ser visualizada en el formulario";
      this.showSuccessMessage = true;
    }
  },
  watch: {
    isSelectionQuestion(newValue) {
      if (!newValue) {
        this.questionOptions = [];
        this.removedQuestionOptions = [];
        this.optionName = "";
        this.updatingOption = false;
        this.optionToUpdateName = "";
        this.optionToUpdatePosition = "";
        this.newOptionName = "";
      } else {
        this.question.label = null;
      }
    },
    isBooleanQuestion(newValue) {
      if (newValue) {
        this.question.label = null;
        this.question.hint = null;
      }
    }
  },
  mounted() {
    if (this.questionToModify) {
      switch (this.questionToModify.questionType.id) {
        case QUESTION_TYPES.SINGLE_CHOICE.id:
          {
            this.filteredQuestionTypes = this.questionTypes.filter(
              questionType =>
                questionType.id === QUESTION_TYPES.SINGLE_CHOICE.id ||
                questionType.id === QUESTION_TYPES.MULTIPLE_CHOICE.id
            );
          }
          break;
        case QUESTION_TYPES.MULTIPLE_CHOICE.id:
          {
            this.filteredQuestionTypes = this.questionTypes.filter(
              questionType =>
                questionType.id === QUESTION_TYPES.MULTIPLE_CHOICE.id
            );
          }
          break;
        case QUESTION_TYPES.TEXT.id:
          {
            this.filteredQuestionTypes = this.questionTypes.filter(
              questionType =>
                questionType.id === QUESTION_TYPES.TEXT.id ||
                questionType.id === QUESTION_TYPES.TEXTAREA.id
            );
          }
          break;
        case QUESTION_TYPES.TEXTAREA.id:
          {
            this.filteredQuestionTypes = this.questionTypes.filter(
              questionType => questionType.id === QUESTION_TYPES.TEXTAREA.id
            );
          }
          break;
        case QUESTION_TYPES.DATE.id:
          {
            this.filteredQuestionTypes = this.questionTypes.filter(
              questionType => questionType.id === QUESTION_TYPES.DATE.id
            );
          }
          break;
        case QUESTION_TYPES.SWITCH.id:
          {
            this.filteredQuestionTypes = this.questionTypes.filter(
              questionType => questionType.id === QUESTION_TYPES.SWITCH.id
            );
          }
          break;
      }

      this.question.id = this.questionToModify.id;
      this.question.name = this.questionToModify.name;
      this.question.label = this.questionToModify.label;
      this.question.hint = this.questionToModify.hint;
      this.question.required = this.questionToModify.required;
      this.question.position = this.questionToModify.position;
      this.question.questionType.id = this.questionToModify.questionType.id;
      this.question.status.id = this.questionToModify.status.id;
      this.questionToModify.options.forEach(option => {
        let optionAux = {
          id: option.id,
          name: option.name,
          position: option.position,
          status: { id: option.status.id },
          edit: false
        };

        this.questionOptions.push(optionAux);
      });
    } else {
      this.filteredQuestionTypes = this.questionTypes;
    }
  }
};
</script>

<style scoped>
.card-background {
  background-color: var(--v-primary-base);
  width: 100%;
}
.card-border {
  border-bottom: solid 2px;
  border-bottom-color: white;
}
.toolbar {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
}
.wrapper {
  overflow-y: scroll !important;
  max-height: 600px !important;
}
.scroll-style::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  background-color: rgba(168, 168, 168, 0.3);
}
.scroll-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  border: solid 1px rgba(255, 255, 255, 0.4);
}
.scroll-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
}
.border ::v-deep fieldset {
  border: 2px solid white;
}
.input-color ::v-deep label {
  color: rgb(236, 236, 236) !important;
}
.input-color ::v-deep input {
  color: white !important;
}
.input-color ::v-deep i {
  color: white !important;
}
.input-color ::v-deep .v-select__selection--comma {
  color: white !important;
}
</style>
