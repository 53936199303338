<template>
  <v-dialog
    v-model="showModal"
    transition="dialog-bottom-transition"
    persistent
    fullscreen
  >
    <v-card>
      <v-toolbar dark color="primary" class="toolbar">
        <v-btn icon dark @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ titleToShow }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="saveSection" :loading="loading"
            >Guardar</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>
      <br />
      <v-card-text>
        <v-container class="mt-7">
          <v-form ref="question">
            <v-row>
              <v-col cols="12" md="6">
                <h1 class="mb-5  text-h6 primary--text">
                  Nombre de la sección
                </h1>
                <v-text-field
                  v-model.trim="section.name"
                  :rules="[rules.required, rules.requiredText]"
                  prepend-inner-icon="mdi-format-title"
                  label="Nombre"
                  maxlength="150"
                  counter
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <h1 class="mb-5 text-h6 primary--text">
                  Descripción de la sección
                </h1>
                <v-text-field
                  v-model.trim="section.description"
                  :rules="[rules.required, rules.requiredText]"
                  prepend-inner-icon="mdi-format-title"
                  label="Descripción"
                  maxlength="300"
                  counter
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" v-if="sectionToModify">
                <h1 class="mb-5 text-h6 primary--text">
                  Posición
                </h1>
                <v-select
                  v-model="section.position"
                  :items="positions"
                  prepend-inner-icon="mdi-order-numeric-ascending"
                  label="Orden de aparición"
                  outlined
                  attach
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <success-message
        :showModal="showSuccessMessage"
        :title="successTitle"
        :message="successMessage"
        @okAction="successfulTransaction()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import SuccessMessage from "@/components/common/notifications/SuccessMessage";
import Repository from "@/services/repositories/repositoryFactory";
const FormRepository = Repository.get("form");
import { STATUS } from "@/config/constants";
export default {
  name: "section-form-modal",
  components: {
    "success-message": SuccessMessage
  },
  props: {
    showModal: { type: Boolean, required: true },
    titleToShow: { type: String, required: true },
    sectionToModify: {
      type: Object,
      default: null
    },
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rules: {
        required: value => !!value || "Campo Obligatorio",
        requiredText: value =>
          /\S/.test(value) || value == "" || "No se admiten campos en blanco"
      },
      loading: false,
      showSuccessMessage: false,
      successTitle: "",
      successMessage: "",
      section: {
        form: {
          id: this.form.id
        },
        name: null,
        description: null,
        position: null,
        status: {
          id: null
        }
      }
    };
  },
  computed: {
    positions() {
      return Array.from({ length: this.form.sections.length }, (x, y) => y + 1);
    }
  },
  methods: {
    validateForm() {
      return this.$refs.question.validate();
    },
    loadForm() {
      this.$emit("loadForm");
    },
    successfulTransaction() {
      this.loadForm();
      this.showSuccessMessage = false;
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModal", false);
    },
    async saveSection() {
      this.savingQuestion = true;
      if (this.validateForm()) {
        if (!this.sectionToModify) {
          await this.createSection();
        } else {
          await this.updateSection();
        }
      }
      this.savingQuestion = false;
    },
    async createSection() {
      this.loading = true;
      this.section.status.id = STATUS.ACTIVE.id;
      this.section.position = this.form.sections.length + 1;
      await FormRepository.createSection(this.section)
        .then(() => {
          this.createdSuccessfully();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateSection() {
      this.loading = true;
      let sectionToUpdate = this.form.sections.find(
        section => section.id === this.section.id
      );
      delete this.section.form;
      if (sectionToUpdate.position != this.section.position) {
        await this.updateAllSections();
      } else {
        await FormRepository.updateSection(this.section)
          .then(() => {
            this.updatedSuccessfully();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async updateAllSections() {
      let sectionsToUpdate = [];
      this.form.sections.forEach(section => {
        let sectionAux = Object.assign({}, section);
        delete sectionAux.status.name;
        delete sectionAux.questions;
        sectionsToUpdate.push(sectionAux);
      });
      let sectionBeingUpdated = sectionsToUpdate.find(
        section => section.id === this.section.id
      );
      let index = sectionsToUpdate.indexOf(sectionBeingUpdated);
      sectionsToUpdate.splice(index, 1);
      sectionsToUpdate.splice(this.section.position - 1, 0, this.section);
      let position = 1;
      sectionsToUpdate.forEach(section => {
        section.position = position;
        position++;
      });
      await FormRepository.updateSectionsOrder(sectionsToUpdate)
        .then(() => {
          this.updatedSuccessfully();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createdSuccessfully() {
      this.successTitle = "¡Se ha creado la sección exitosamente!";
      this.successMessage =
        "La sección se ha registrado y podrá ser visualizada en el formulario";
      this.showSuccessMessage = true;
    },
    updatedSuccessfully() {
      this.successTitle = "¡Se ha modificado la sección exitosamente!";
      this.successMessage =
        "La sección se ha modificado y podrá ser visualizada en el formulario";
      this.showSuccessMessage = true;
    }
  },
  mounted() {
    if (this.sectionToModify) {
      this.section.id = this.sectionToModify.id;
      this.section.name = this.sectionToModify.name;
      this.section.description = this.sectionToModify.description;
      this.section.position = this.sectionToModify.position;
      this.section.status.id = this.sectionToModify.status.id;
    }
  }
};
</script>

<style scoped>
.toolbar {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
}
</style>
