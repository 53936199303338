<template>
  <div>
    <v-card class="fill-height tabs-height" elevation="10">
      <form-loader v-if="loading" />
      <error-view v-else-if="errorLoading" />
      <v-tabs
        v-else-if="form"
        :vertical="$vuetify.breakpoint.lgAndUp"
        center-active
        show-arrows
        class="fill-height tabs-height tabs-background tabs-arrow-prev-background tabs-arrow-next-background"
        dark
      >
        <v-tab
          class="justify-content tabs-shadow-background"
          v-for="(section, index) in form.sections"
          :key="`${index}-${section.id}`"
        >
          <v-icon left>mdi-clipboard-file</v-icon>
          Sección - {{ section.position }}
        </v-tab>
        <br />
        <v-btn
          class="button-border button-background-color"
          v-if="$vuetify.breakpoint.lgAndUp"
          tile
          outlined
          @click="createSection"
          ><v-icon>mdi-plus</v-icon>Añadir sección</v-btn
        >
        <v-tab-item
          class="fill-height tabs-height wrapper scroll-style"
          v-for="(section, index) in form.sections"
          :key="`${index}-${section.id}`"
        >
          <v-btn
            class="button-border button-text-color"
            color="#F2F2F2"
            v-if="$vuetify.breakpoint.mdAndDown"
            block
            tile
            elevation="0"
            @click="createSection"
            ><v-icon>mdi-plus</v-icon>Añadir sección</v-btn
          >
          <form-section-template
            :form="form"
            :section="section"
            :questionTypes="questionTypes"
            :numberOfSectionsWithQuestions="numberOfSectionsWithQuestions"
            @loadForm="loadForm"
          />
        </v-tab-item>
      </v-tabs>
      <section-form-modal
        v-if="showSectionModal"
        :showModal="showSectionModal"
        :titleToShow="sectionModalTitle"
        :form="form"
        @closeModal="openSectionModal"
        @loadForm="loadForm"
      />
    </v-card>
  </div>
</template>

<script>
import FormSectionTemplate from "./FormSectionTemplate";
import Repository from "@/services/repositories/repositoryFactory";
const FormRepository = Repository.get("form");
import SectionFormModal from "./SectionFormModal";
import FormLoader from "@/components/common/loaders/FormLoader";
import ErrorView from "@/components/common/notifications/ErrorView";
export default {
  name: "forms",
  components: {
    "form-section-template": FormSectionTemplate,
    "section-form-modal": SectionFormModal,
    "error-view": ErrorView,
    "form-loader": FormLoader
  },
  data() {
    return {
      value: null,
      showSectionModal: false,
      sectionModalTitle: "",
      loading: false,
      errorLoading: false,
      form: null,
      questionTypes: [],
      numberOfSectionsWithQuestions: 0
    };
  },
  methods: {
    setValue(value) {
      this.value = value;
    },
    openSectionModal(visibility) {
      this.showSectionModal = visibility;
    },
    createSection() {
      this.sectionModalTitle = `Crear sección`;
      this.openSectionModal(true);
    },
    async loadForm() {
      this.loading = true;
      this.numberOfSectionsWithQuestions = 0;

      this.form = await FormRepository.getFormById(
        process.env.VUE_APP_VENUSA_LIVE_FORM_ID
      ).catch(() => {
        this.errorLoading = true;
      });

      if (!this.errorLoading) {
        if (this.form?.sections?.length) {
          this.form.sections.forEach(section => {
            if (section.questions?.length) {
              this.numberOfSectionsWithQuestions++;
            }
          });
        }

        this.questionTypes = await FormRepository.getQuestionTypes()
          .catch(() => {
            this.errorLoading = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  mounted() {
    this.loadForm();
  }
};
</script>

<style scoped>
.justify-content {
  justify-content: end;
}
.tabs-height {
  height: 100%;
}
.wrapper {
  overflow-y: scroll !important;
  min-height: 833px !important;
  max-height: 833px !important;
}
.scroll-style::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  background-color: rgba(168, 168, 168, 0.3);
}
.scroll-style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  border: solid 1px rgba(255, 255, 255, 0.4);
}
.scroll-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
}
.tabs-background ::v-deep .v-tabs-bar__content {
  color: white !important;
  background: rgb(219, 120, 219);
  background: linear-gradient(
    180deg,
    rgba(219, 120, 219, 1) 12%,
    rgba(205, 140, 236, 1) 100%
  );
}
.tabs-arrow-prev-background ::v-deep .v-slide-group__prev {
  background: linear-gradient(
    180deg,
    rgba(219, 120, 219, 1) 12%,
    rgba(205, 140, 236, 1) 100%
  );
}
.tabs-arrow-next-background ::v-deep .v-slide-group__next {
  background: linear-gradient(
    180deg,
    rgba(219, 120, 219, 1) 12%,
    rgba(205, 140, 236, 1) 100%
  );
}
.button-border {
  border: 0;
}
.button-text-color {
  color: #5f5f5fde;
}
.button-background-color {
  background: #ffffff45;
}
</style>
