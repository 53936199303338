<template>
  <vue-form-generator
    class="vue-form-generator-border-style hint-size"
    :schema="schema"
    :model="model"
    :options="formOptions"
  ></vue-form-generator>
</template>

<script>
import "vue-form-generator/dist/vfg.css";
import { QUESTION_TYPES } from "@/config/constants";
export default {
  name: "form-input",
  props: {
    schema: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      model: {
        value: null
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    };
  },
  methods: {
    retrieveData() {
      let data = {
        content: null,
        question: {
          id: this.schema.fields[0].id
        }
      };
      if (
        this.schema.fields[0].type === QUESTION_TYPES.SINGLE_CHOICE.name ||
        this.schema.fields[0].type === QUESTION_TYPES.MULTIPLE_CHOICE.name
      ) {
        data = { ...data, optionAnswers: [] };
        if (this.model.value) {
          this.model.value.forEach(option => {
            if (this.schema.fields[0].startValue) {
              delete data.question;
              let selectedOption = this.schema.fields[0].startValue.find(
                optionSelected => optionSelected.option.id === option.id
              );
              if (selectedOption) {
                data.optionAnswers.push({
                  id: selectedOption.id,
                  option: { id: option.id }
                });
              } else {
                data.optionAnswers.push({ option: { id: option.id } });
              }
            } else {
              data.optionAnswers.push({ option: { id: option.id } });
            }
          });
        }
      } else {
        if (this.schema.fields[0].type === QUESTION_TYPES.SWITCH.name) {
          data.content = !!this.model.value;
        } else {
          data.content = this.model.value;
        }
      }
      return data;
    }
  },
  mounted() {
    if (
      this.schema.fields[0].startValue ||
      this.schema.fields[0].startValue === false
    ) {
      this.model.value = this.schema.fields[0].startValue;
    }
  }
};
</script>

<style>
.vue-form-generator-border-style > fieldset {
  border: 0;
}

.hint-size /deep/ .hint {
  line-height: 1.375rem;
  font-size: 0.875rem;
  font-style: normal;
}
</style>
